// This file can be replaced during build by using the `fileReplacements` array.
// `ng build` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  appVersion: 'v8.0.38',
  USERDATA_KEY: 'authf649fc9a5f55',
  isMockEnabled: true,
  // authApiUrl: 'http://localhost:5951',
  // workspaceApiUrl: 'http://localhost:5987',
  // itemApiUrl: 'http://localhost:5149',
  // campaignApiUrl: 'http://localhost:5002',
  // activityApiUrl: 'http://localhost:5004',
  // conversionApiUrl: 'http://localhost:5003',
  // campaignAggregatorUrl: 'http://localhost:5219',
  // promoterApiUrl: 'http://localhost:5016',
  // affiliateApiUrl: 'http://localhost:5050',
  // WalletApiUrl: 'http://localhost:5012',
  // integrationApiUrl: 'http://localhost:5323',
  // insightsApiUrl: 'http://localhost:5321',
  // integrationCallbackUrl: 'http://indanalocal.com/settings/resync-store',

  authApiUrl: 'https://app.indana.io/api/auth',
  workspaceApiUrl: 'https://app.indana.io/api/workspace',
  itemApiUrl: 'https://app.indana.io/api/item',
  campaignApiUrl: 'https://app.indana.io/api/campaign',
  conversionApiUrl: 'https://app.indana.io/api/conversion',
  campaignAggregatorUrl: 'https://app.indana.io/api/campaign-ag',
  promoterApiUrl: 'https://app.indana.io/api/promoter',
  affiliateApiUrl: 'https://app.indana.io/api/affiliate',
  WalletApiUrl: 'https://app.indana.io/api/wallet',
  integrationApiUrl: 'https://app.indana.io/api/integration',
  insightsApiUrl: 'https://app.indana.io/api/insight',
  integrationCallbackUrl: 'https://app.indana.io/w/settings/integration',
  activityApiUrl: 'https://app.indana.io/api/activity',
  stateMangementSecretKey: '123asdzxc',

};
/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
