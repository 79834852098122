<!-- <div *ngIf="isLoading$ | async" class="loading">

</div> -->



<div *ngIf="isLoading$ | async" class="loading">
  <div class="text-center"> <!-- This centers both the image and the spinner -->
    <img width="200px" src="../../../../assets/media/logos/indana-logo.png" alt="Logo" class="d-block mx-auto">
    <div class="spinner-border text-primary mt-3" role="status"></div>
  </div>


</div>