

  <div class="container">
    <!--begin::Title-->
    <h1 class="title">
      عذراً! لا يوجد بيانات فى هذه الصفحة
    </h1>
    <!--end::Title-->

    <!--begin::Text-->
    <div class="subtitle">
      عذراً، لم نتمكن من العثور على البيانات التي كنت تبحث عنها.
    </div>
    <!--end::Text-->

    <!--begin::Illustration-->
    <div class="illustration">
      <img src="./assets/media/auth/404No Data.png" alt="رسالة خطأ 404">
    </div>
    <!--end::Illustration-->

    <!--begin::Link-->
    <div class="mb-0">
      <a href="/" class="btn-primary">العودة إلى الصفحة الرئيسية</a>
    </div>
    <!--end::Link-->
  </div>

