import { AddBankPaymentRequest, DeleteBankPaymentRequest, GetPromoterPaymentMethodsResponse, UpdateBankPaymentRequest, UpdatePromoterProfilePicRequest, UpdatePromoterRequest, UpdatePromoterTargetCategoriesRequest, UpdatePromoterTargetCountriesRequest } from "@shared/api/promoterApi";
import { AddSocialMediaInsightsRequest } from "@shared/api/promoterApi/requests/AddSocialMediaInsightsRequest";

export namespace PromoterStateActions {
  export class AddBankPaymentMethod {
    static readonly type = '[prompter] Add bank payment method';
    constructor(public payload: AddBankPaymentRequest) { }
  }
  export class DeleteBankPaymentMethod {
    static readonly type = '[promoter] Delete bank payment method';
    constructor(public payload: DeleteBankPaymentRequest) { }
  }
  export class UpdateBankPaymentMethod {
    static readonly type = '[promoter] Update bank payment method';
    constructor(public payload: UpdateBankPaymentRequest) { }
  }
  export class GetPaymentMethods {
    static readonly type = '[promoter] Get all bank payment methods';
    constructor() { }
  }
  export class Get {
    static readonly type = '[promoter] Get promoter'
    constructor(public promoter: string, public navigate: boolean) { }
    //TODO modify according to access tokenze
  }
  export class Update {
    static readonly type = "[promoter] update promoter";
    constructor(public payload: UpdatePromoterRequest) { }
  }
  export class UpdateTargetCountries {
    static readonly type = '[promoter] Update promoter target countries';
    constructor(public payload: UpdatePromoterTargetCountriesRequest) { }
  }
  export class AddSocialMediaInsights {
    static readonly type = '[promoter] Update promoter Social Media Insights';
    constructor(public payload: AddSocialMediaInsightsRequest) { }
  }
  export class UpdateTargetCategories {
    static readonly type = '[promoter] Update promoter target categories';
    constructor(public payload: UpdatePromoterTargetCategoriesRequest) { }
  }
  export class UpdateProfilePic {
    static readonly type = '[promoter] Update promoter profile picture'
    constructor(public payload: UpdatePromoterProfilePicRequest) { }
  }
}
