import { Injectable } from '@angular/core';
import {
  HttpEvent,
  HttpInterceptor,
  HttpHandler,
  HttpRequest,
} from '@angular/common/http';
import { Observable } from 'rxjs';
import { finalize } from 'rxjs/operators';
import { LoadingService } from '@shared/services/loading.service';

@Injectable()
export class LoaderInterceptor implements HttpInterceptor {
  // List of API endpoints to skip showing the loader
  private skipLoaderEndpoints: string[] = [
    'payment-status',
    'create-checkout',
    'AddSocialMediaInsights',
    'Apply',
    'Cancel',
    'CreateWithdrawRequest',
    'Identity'
  ];

  constructor(private loadingService: LoadingService) { }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    // Check if the current request URL matches any of the endpoints in the skip list
    const shouldSkipLoader = this.skipLoaderEndpoints.some(endpoint => req.url.includes(endpoint));

    if (!shouldSkipLoader) {
      this.loadingService.show();
    }

    return next.handle(req).pipe(
      finalize(() => {
        if (!shouldSkipLoader) {
          this.loadingService.hide();
        }
      })
    );
  }
}
