import { NoticeService } from '@shared/services/notice.service';
import { AuthService } from './../services/auth.service';
import { AuthHTTPService } from './../services/auth-http/auth-http.service';
import { LoadingHandler } from './../../../@shared/state-helpers/loading-handler/LoadingHandler';
import { AuthStateModel } from '../models/authStateModel';
import { Action, NgxsOnInit, Selector, State, StateContext } from '@ngxs/store';
import { Injectable } from '@angular/core';
import { AuthStateActions } from '../actions/auth.action';
import { catchError, of, tap } from 'rxjs';
import { Navigate } from '@ngxs/router-plugin';
// import { AuthPaths } from '@shared/paths';
import { UserTypes } from '@shared/constants';
import { User } from '../models/user';
import { StateClear, StateReset, StateResetAll } from 'ngxs-reset-plugin';
import { PromoterStateActions } from '@shared/stateManagement/promoterStateStore/actions/promoter.action';
import { WorkspaceStateActions } from '@shared/stateManagement/workspaceStateStore/actions/workspace.action';
import { HttpErrorResponse, HttpResponse } from '@angular/common/http';
import { AuthModel } from '../models/auth.model';
// import { PromoterStateActions } from '@features/promoter/_store/actions/promoter-state.actions';
// import { WorkspaceStateActions } from '@features/workspace/_store/actions/workspace.action';

@State<AuthStateModel>({
  name: 'auth',
  defaults: {
    accessToken: null,
    refreshToken: null,
    id: null,
    email: null,
    userType: null,
    isLoading: false,
    phone: null,
    punishments: [],
    errorMessage: null,
    successMessage : null,
    isOtpVerified: false,
    isOtpSent: false,
    isEmailSent:false,
    isEmailVerified:false,
    setUpProfileDetails:false,
    chargeWallet:false,
    syncProducts:false,
    createFirstCampaign:false,
    subscribeFirstCampaign:false,
    addBankAccountInfo:false,
    addSocialMediaInfo:false
  },
})
@Injectable()
export class AuthBaseState extends LoadingHandler<AuthStateModel> implements NgxsOnInit {
  @Selector()
  static getToken(state: AuthStateModel) {
    return state.accessToken;
  }
  @Selector()
  static getErrorMessage(state: AuthStateModel) {
    return state.errorMessage;
  }
  @Selector()
  static getUserPhone(state: AuthStateModel): string {
    return state.phone||'';
  }
  @Selector()
  static checkUserProfileDetails(state: AuthStateModel): boolean {
    return state.setUpProfileDetails;
  }
  @Selector()
  static userChargeWallet(state: AuthStateModel): boolean {
    return state.chargeWallet;
  }  @Selector()
  static userSyncProducts(state: AuthStateModel): boolean {
    return state.syncProducts;
  }  @Selector()
  static userCreateFirstCampaign(state: AuthStateModel): boolean {
    return state.createFirstCampaign;
  }
  @Selector()
  static useraddBankAccountInfo(state: AuthStateModel): boolean {
    return state.addBankAccountInfo;
  }
  // Selector for OTP sent state
  @Selector()
  static isOtpSent(state: AuthStateModel): boolean {
    return state.isOtpSent;
  }

  // Selector for OTP verified state
  @Selector()
  static isOtpVerified(state: AuthStateModel): boolean {
    return state.isOtpVerified;
  }
  // Selector for Email sent state
  @Selector()
  static isEmailSent(state: AuthStateModel): boolean {
    return state.isEmailSent;
  }

  // Selector for Email verified state
  @Selector()
  static isEmailVerified(state: AuthStateModel): boolean {
    return state.isEmailVerified;
  }

  @Selector()
  static getSuccessMessage(state: AuthStateModel) {
    return state.successMessage;
  }


  @Selector()
  static isAuthenticated(state: AuthStateModel): boolean {
    return !!state.accessToken;
  }

  @Selector()
  static isAdmin(state: AuthStateModel): boolean {
    return state.userType === UserTypes.Admin;
  }
  @Selector()
  static isPromoter(state: AuthStateModel): boolean {
    return state.userType === UserTypes.Promoter;
  }
  @Selector()
  static isAdvertiser(state: AuthStateModel): boolean {
    return state.userType === UserTypes.Advertiser;
  }

  @Selector()
  static isLoading(state: AuthStateModel): boolean {
    return state.isLoading;
  }

  @Selector()
  static getUserType(state: AuthStateModel): string | null {
    return state.userType;
  }

  @Selector()
  static getPunishments(state: AuthStateModel): string[] | null {
    return state.punishments;
  }

  @Selector()
  static refreshToken(state: AuthStateModel): boolean {
    return !!state.refreshToken;
  }
  @Selector()
  static getUser(state: AuthStateModel): User {
    return {
      email: state.email,
      id: state.id,
      phone: state.phone,
      userType: state.userType,
      chargeWallet:state.chargeWallet,
      createFirstCampaign:state.createFirstCampaign,
      setUpProfileDetails:state.setUpProfileDetails,
      syncProducts:state.syncProducts,
      addBankAccountInfo:state.addBankAccountInfo,
      addSocialMediaInfo:state.addSocialMediaInfo,
      subscribeFirstCampaign:state.subscribeFirstCampaign
    };
  }
  @Selector()
  static checkUserJourney(state: AuthStateModel) {
    return {
      chargeWallet:state.chargeWallet,
      createFirstCampaign:state.createFirstCampaign,
      setUpProfileDetails:state.setUpProfileDetails,
      syncProducts:state.syncProducts
    };
  }
  @Selector()
  static checkPromoterJourney(state: AuthStateModel) {
    return {
      addBankAccountInfo:state.addBankAccountInfo,
      subscribeFirstCampaign:state.subscribeFirstCampaign,
      setUpProfileDetails:state.setUpProfileDetails,
      addSocialMediaInfo:state.addSocialMediaInfo
    };
  }
  /**
   *
   */
  constructor(
    private authHttpService: AuthHTTPService,
    private authService: AuthService,
    private notifiy: NoticeService
  ) {
    super();
  }
  ngxsOnInit(ctx: StateContext<any>): void {
    ctx.patchState({
      errorMessage: null,
    });
  }

  @Action(AuthStateActions.Login)
  onLogin(ctx: StateContext<AuthStateModel>, { payload }: AuthStateActions.Login) {
    this.startLoading(ctx);
    return this.authHttpService.login(payload.mobileNumber).pipe(
      tap({
        next: (res: HttpResponse<any>) => {  // Access full HttpResponse
if(res.status==200)
{
          ctx.patchState({
            isOtpSent: true,
            isOtpVerified:false,
            isLoading: false,

          });
        }
           
        },
        error: (err) => {
          ctx.patchState({ isLoading: false, isOtpSent: false ,isOtpVerified:false});

          ctx.dispatch(new AuthStateActions.LoginFailed(err));
        },
      })
    );
  }

  @Action(AuthStateActions.CheckUserPhone)
  onCheckPhoneUser(ctx: StateContext<AuthStateModel>, { payload }: AuthStateActions.CheckUserPhone) {
    this.startLoading(ctx);
    return this.authHttpService.CheckUserPhone(payload.mobileNumber).pipe(
      tap({
        next: (res: HttpResponse<any>) => {  // Access full HttpResponse
if(res.status==200)
{
          ctx.patchState({
            isOtpSent: true,
            isOtpVerified:false,
            isLoading: false,

          });
        }
           
        },
        error: (err) => {
          ctx.patchState({ isLoading: false, isOtpSent: false,isOtpVerified:false });

          ctx.dispatch(new AuthStateActions.LoginFailed(err));
        },
      })
    );
  }
  @Action(AuthStateActions.UpdateEmail)
  updateEmail(ctx: StateContext<AuthStateModel>, { payload }: AuthStateActions.UpdateEmail) {
    return this.authHttpService.updateEmail(payload).pipe(
      tap({
        next: () => {
          ctx.patchState({
            isEmailSent: true,
            isEmailVerified:false

          })
        },
      })
    );
  }
  @Action(AuthStateActions.VerifyEmail)
  onVerifyEmail(ctx: StateContext<AuthStateModel>, { payload }: AuthStateActions.VerifyEmail) {
    this.startLoading(ctx);
    return this.authHttpService.verifyEmail(payload.email,payload.verifcationCode).pipe(
      tap({
        next: (res: HttpResponse<any>) => {  // Access full HttpResponse
if(res.status==200)
{
          ctx.patchState({
            isEmailVerified: true,
            isEmailSent:false,
            email:payload.email,
            setUpProfileDetails:true
          });
          this.notifiy.successNotice('تم النحقق من البريد الالكترونى بنجاح');

        }
           
        },
        error: (err) => {
          ctx.patchState({ isLoading: false, isEmailSent: false,isEmailVerified:false   });

          ctx.dispatch(new AuthStateActions.LoginFailed(err));
        },
      })
    );
  }
  @Action(AuthStateActions.updateUserJourney)
  updateUserJourney(ctx: StateContext<AuthStateModel>, { payload }: AuthStateActions.updateUserJourney) {
    this.startLoading(ctx);
  
    // Find the property that is true and update the state for it
    const journeyProperties = [
      { property: 'syncProducts', value: payload.syncProducts },
      { property: 'createFirstCampaign', value: payload.createFirstCampaign },
      { property: 'chargeWallet', value: payload.chargeWallet },
      { property: 'addBankAccountInfo', value: payload.addBankAccountInfo },
      { property: 'subscribeFirstCampaign', value: payload.subscribeFirstCampaign },
      { property: 'addSocialMediaInfo', value: payload.addSocialMediaInfo },

    ];
    // Find the property with true value
    const updatedProperty = journeyProperties.find(prop => prop.value === true);
    console.log(updatedProperty.property+"updatedProperty");
  
    if (updatedProperty) {
      const state = ctx.getState();  // Get the current state
      // Check if the current state value is false before updating
      if (state[updatedProperty.property as keyof AuthStateModel] != true) {
        console.log(updatedProperty.property+"updatedProperty2");

        ctx.patchState({ [updatedProperty.property]: updatedProperty.value });
      }
    }
  
    // Optionally, you can handle the case where none of the flags is true (if needed)
    return;
  }

  
  
  

  // //test by our
  // @Action(AuthStateActions.SendOtp)
  // onSendOtp(ctx: StateContext<AuthStateModel>, action: AuthStateActions.SendOtp) {
  //   ctx.patchState({ isLoading: true }); // Set loading to true
  //   return this.authHttpService.sendOtp(action.payload.mobileNumber).pipe(
  //     tap({
  //       next: (res) => {
  //         ctx.patchState({
  //           isOtpSent: true,
  //         });    

  //           },
  //     }),
  //     catchError((err) => {
  //       ctx.patchState({ isLoading: false, isOtpSent: false });
  //       console.error('Error sending OTP:', err);
  //       return of(err); // Handle error appropriately
  //     })
  //   );
  // }

  @Action(AuthStateActions.VerifyOtp)
  onVerifyOtp(ctx: StateContext<AuthStateModel>, { payload }: AuthStateActions.VerifyOtp) {
    this.startLoading(ctx);
    return this.authHttpService.verifyOtp(payload.mobileNumber,payload.otp).pipe(
      tap({
        next: (res) => {
          ctx.patchState({
            isOtpVerified: true,
            isOtpSent:false,
            phone:payload.mobileNumber,
            isLoading: false,

          });
          
          if(payload.Islogged==false)
          {
            console.log(payload.Islogged+"لوجن")

          ctx.dispatch(new AuthStateActions.LoginSuccess(res));
          }
        },
      }),
      catchError((error) => {
        ctx.patchState({ isLoading: false, isOtpVerified: false });
        console.error('Error verifying OTP:', error);
        return of(error); // Handle error appropriately
      })
    );
  }
  // @Action(AuthStateActions.VerifyOtpForNewUsers)
  // onVerifyOtpForNewUsers(ctx: StateContext<AuthStateModel>, { payload }: AuthStateActions.VerifyOtpForNewUsers) {
  //   this.startLoading(ctx);
  //   return this.authHttpService.verifyOtpForNewUsers(payload).pipe(
  //     tap({
  //       next: (res) => {
  //         ctx.patchState({
  //           isOtpVerified: true,
  //         });
  //         const state = ctx.getState();

  //         let authmodel = new AuthModel(state.accessToken, state.refreshToken, null, []); 
          
  //         if (state.accessToken !== null) {
  //           ctx.dispatch(new AuthStateActions.LoginSuccess(authmodel));
  //         }
          
  //       },
  //     }),
  //     catchError((error) => {
  //       ctx.patchState({ isLoading: false, isOtpVerified: false });
  //       console.error('Error verifying OTP:', error);
  //       return of(error); // Handle error appropriately
  //     })
  //   );
  // }
  @Action(AuthStateActions.SignUp)
  onSignup(ctx: StateContext<AuthStateModel>, { payload }: AuthStateActions.SignUp) {
    this.startLoading(ctx);
  
    return this.authHttpService.signUp(payload).pipe(
      tap({
      
        next: (res: HttpResponse<any>) => {  // Access full HttpResponse
          console.log(res.status + " - status code");  // Print the status code
if(res.status==200)
{
          ctx.patchState({
            isOtpSent: true,
            successMessage: "AUTH.ALERT.SIGNUP.SUCCESS",
            isOtpVerified:false

          });
        } },
        error: (err) => {
          ctx.patchState({ isLoading: false, isOtpSent: false,isOtpVerified:false });
  
          ctx.dispatch(new AuthStateActions.LoginFailed(err));
        },
        finalize: () => this.stopLoading(ctx),
      })
    );
  }

  @Action(AuthStateActions.Logout)
  onLogout(ctx: StateContext<AuthStateModel>) {
    return this.authHttpService.logout().pipe(
      tap(() => {
        ctx.patchState({
          isOtpSent: false,
          isOtpVerified:false,
          successMessage: "AUTH.ALERT.SIGNOUT.SUCCESS"

        }); 
        ctx.dispatch(new AuthStateActions.LogoutSuccess());
      
       
      })
    );
  }

  @Action(AuthStateActions.LoginFailed)
  onLoginFailed(ctx: StateContext<AuthStateModel>) {
    ctx.patchState({
      isLoading: false,
    });
  }

  @Action(AuthStateActions.LoginSuccess)
  onLoginSuccess(ctx: StateContext<AuthStateModel>, { result }: AuthStateActions.LoginSuccess) {
    const tokenClaims = this.authService.getTokenClaims(result.accessToken!);
    ctx.patchState({
      accessToken: result.accessToken,
      refreshToken: result.refreshToken,
      userType: tokenClaims.userType,
      id: tokenClaims.unique_name,
    });
    ctx.dispatch(new AuthStateActions.LoginRedirect());
  }

  @Action(AuthStateActions.LogoutSuccess)
  onLogoutSuccess(ctx: StateContext<AuthStateModel>) {
    ctx.patchState({
      accessToken: null,
      refreshToken: null,
      email: null,
      id: null,
      isLoading: false,
      userType: null,
    });
    ctx.dispatch(new AuthStateActions.LogoutRedirect());
  }

  @Action(AuthStateActions.LoginRedirect)
  onLoginRedirect(ctx: StateContext<AuthStateModel>) {
    const state = ctx.getState();
    if (state.accessToken !== null) {
      ctx.dispatch(new AuthStateActions.GetUser());
    }
  }
  @Action(AuthStateActions.LogoutRedirect)
  onLogoutRedirect(ctx: StateContext<AuthStateModel>) {
    ctx.dispatch(new Navigate(['/auth/login']));
    ctx.dispatch(new StateClear(AuthBaseState));
  }

  @Action(AuthStateActions.RefreshToken)
  onRefreshToken(ctx: StateContext<AuthStateModel>) {
    const state = ctx.getState();
    return this.authHttpService.refreshToken(state.refreshToken!).pipe(
      tap({
        next: (res) => {
          ctx.patchState({
            ...state,
            accessToken: res.accessToken,
            refreshToken: res.refreshToken,
          });
        },
        error: () => {
          ctx.dispatch(new AuthStateActions.LogoutSuccess());
        },
      })
    );
  }
  @Action(AuthStateActions.ChangePassword)
  changePassword(ctx: StateContext<AuthStateModel>, { payload }: AuthStateActions.ChangePassword) {
    return this.authHttpService.changePassword(payload).pipe(
      tap({
        next: () => this.notifiy.successNotice('AUTH.ALERT.PASSWORD.SUCCESS'),
      })
    );
  }
 
  @Action(AuthStateActions.UpdatePhoneNumber)
  updateUser(ctx: StateContext<AuthStateModel>, { payload }: AuthStateActions.UpdatePhoneNumber) {
    return this.authHttpService.updatePhone(payload).pipe(
      tap({
        next: () => {
          ctx.patchState(payload);
          this.notifiy.successNotice('تم التعديل بنجاح');
        },
      })
    );
  }
  @Action(AuthStateActions.GetUser)
  getUser(ctx: StateContext<AuthStateModel>) {
    const state = ctx.getState();
    return this.authHttpService.getUserByToken().pipe(
      tap({
        next: (data) => {
          ctx.patchState({
            ...state,
            phone: data.phone,
            email: data.email,
            userType: data.userType,
            setUpProfileDetails:data.setUpProfileDetails,
          });
          if (state.userType === UserTypes.Promoter) {
            ctx.patchState({
              addBankAccountInfo:data.addBankAccountInfo,
              subscribeFirstCampaign:data.subscribeFirstCampaign,
              addSocialMediaInfo:data.addSocialMediaInfo
            });
            ctx.dispatch(new PromoterStateActions.Get(state.id!, true));
          } else if (state.userType === UserTypes.Advertiser) {
            ctx.patchState({
              chargeWallet:data.chargeWallet,
              syncProducts:data.syncProducts,
              createFirstCampaign:data.createFirstCampaign
            });
            ctx.dispatch(new WorkspaceStateActions.Get(state.id!, true));
          }
        },
        finalize: () => {
          this.stopLoading(ctx);
        },
      })
    );
  }
  @Action(AuthStateActions.SetupQuickAccount)
  setupQuickAccount(
    ctx: StateContext<AuthStateModel>,
    { authCode }: AuthStateActions.SetupQuickAccount
  ) {
    return this.authHttpService.setupQuickAccount(authCode).pipe(
      tap({
        next: (res) => {
          console.log(res+"Phone Result");
if(res&&res!='')
{
          ctx.patchState({
            isOtpSent: true,
            isOtpVerified:false,
            phone:res
          });
          // ctx.dispatch(new Navigate(AuthPaths.LoginPathComponents));
        }
      },
        error: (err) => {
          ctx.patchState({ isLoading: false, isOtpSent: false,isOtpVerified:false });
  
          ctx.dispatch(new AuthStateActions.LoginFailed(err));
        },
        finalize: () => this.stopLoading(ctx),
      })
    );
  }

  @Action(AuthStateActions.SetPassword)
  setPassword(ctx: StateContext<AuthStateModel>, { payload }: AuthStateActions.SetPassword) {
    this.startLoading(ctx);
    return this.authHttpService.setPassword(payload).pipe(
      tap({
        next: () => {
          this.notifiy.successNotice('AUTH.ALERT.VerifyEmail.SUCCESS');
          ctx.patchState({
            isOtpSent: true,
            isOtpVerified:false
          });
          // ctx.dispatch(new Navigate(AuthPaths.LoginPathComponents));
        },
        finalize: () => this.stopLoading(ctx),
      })
    );
  }
  @Action(AuthStateActions.ResendEmail)
  resendEmail(ctx: StateContext<AuthStateModel>, { email }: AuthStateActions.ResendEmail) {
    this.startLoading(ctx);
    return this.authHttpService.resendEmail(email).pipe(
      tap({
        next: () => {
          this.notifiy.successNotice('AUTH.ALERT.RESNED_EMAIL.SUCCESS');
        },
        finalize: () => this.stopLoading(ctx),
      })
    );
  }

  @Action(AuthStateActions.ResetPassword)
  resetPassword(ctx: StateContext<AuthStateModel>, {email}: AuthStateActions.ResetPassword){
    this.startLoading(ctx);
    return this.authHttpService.resetPassword(email).pipe(
      tap({
        next: () => {
          ctx.patchState({
            successMessage: "AUTH.ALERT.RESNED_EMAIL.SUCCESS"

          })
        },

        error: (err: HttpErrorResponse) =>{
          ctx.patchState({
            errorMessage: err.message
          })
        } ,
        finalize : () => this.stopLoading(ctx)
      })
    )
  }
  @Action(AuthStateActions.SetErrorMessage)
  onSetErrorMessage(
    ctx: StateContext<AuthStateModel>,
    { message }: AuthStateActions.SetErrorMessage
  ) {
    ctx.patchState({
      errorMessage: message,
    });
  }
  @Action(AuthStateActions.ClearSuccessMessage)
  onClearSuccessMessage(ctx: StateContext<AuthStateModel>){

    ctx.patchState({
      successMessage: null
    })
  }
  @Action(AuthStateActions.ClearErrorMessage)
  onClearErrorMessage(ctx: StateContext<AuthStateModel>){
    ctx.patchState({
      errorMessage: null
    })
  }
}
