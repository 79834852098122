import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { DrawerComponent, MenuComponent, ScrollComponent, ScrollTopComponent, StickyComponent, ToggleComponent } from '@core/kt/components';
import { ThemeModeService } from '@core/partials/layout/theme-mode-switcher/theme-mode.service';

@Component({
  selector: 'app-not-exist-data',
  templateUrl: './not-exist-data.component.html',
  styleUrls: ['./not-exist-data.component.scss']
})
export class NotExistDataComponent {
  constructor(private router: Router, private modeService: ThemeModeService) {}

  routeToDashboard() {
    this.router.navigate(['dashboard']);
    setTimeout(() => {
      ToggleComponent.reinitialization();
      ScrollTopComponent.reinitialization();
      DrawerComponent.reinitialization();
      StickyComponent.bootstrap();
      MenuComponent.reinitialization();
      ScrollComponent.reinitialization();
    }, 200);
  }

}
