import { PaginationModule } from './pagination/pagination.module';
import { InlineSVGModule } from 'ng-inline-svg-2';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslationModule } from './i18n';
import { HttpClientModule } from '@angular/common/http';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { PipesModule } from './pipes/pipes.module';
import { LineChartComponent } from './charts/line-chart/line-chart.component';
import { DoughnutChartComponent } from './charts/doughnut-chart/doughnut-chart.component';
import { NotExistDataComponent } from './components/Shared Pages/not-exist-data/not-exist-data.component';
import { LoadingComponent } from './components/loading/loading.component';



@NgModule({
  declarations: [

       LineChartComponent,
       DoughnutChartComponent,
       NotExistDataComponent,
    LineChartComponent,
    DoughnutChartComponent,
    LoadingComponent
  ],
  imports: [
    CommonModule,
    TranslationModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    InlineSVGModule,
    PaginationModule,
    NgbModule,
    PipesModule
  ],
  exports: [
    CommonModule,
    TranslationModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    InlineSVGModule,
    PaginationModule,
    NgbModule,
    PipesModule,
    LineChartComponent,
    DoughnutChartComponent,
    NotExistDataComponent,

    LoadingComponent,
    DoughnutChartComponent

  ]
})
export class SharedModule { }
