import { AfterViewChecked, AfterViewInit, Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { v4 as uuidv4 } from 'uuid';

import { Chart, registerables } from 'chart.js';
Chart.register(...registerables);

@Component({
  selector: 'app-line-chart',
  templateUrl: './line-chart.component.html',
  styleUrls: ['./line-chart.component.scss'],
})
export class LineChartComponent implements OnInit, AfterViewInit, AfterViewChecked, OnChanges {
  ngOnChanges(changes: SimpleChanges): void {
    console.log("chartData", this.chartData);

  }
  @Input() chartData: any[];
  @Input() chartLable: any[];
  chartId: string;
  ngAfterViewChecked(): void {
    this.chart.data.datasets[0].data = this.chartData;
    this.chart.update();
  }
  ngOnInit(): void {
    this.chartId = uuidv4();
  }
  ngAfterViewInit(): void {
    this.createChart();

  }
  public chart: any;

  createChart() {
    const data = {
      labels: this.chartLable,
      datasets: [
        {
          label: '(SAR) المبيعات',
          data: this.chartData,
          fill: true,
          borderColor: '#00FFFF',
          tension: 0.5,
          backgroundColor: 'rgb(0, 204, 204, 0.3)',
        },
      ],
    };

    this.chart = new Chart(this.chartId, {
      type: 'line', // Chart type
      data: data,
      options: {
        responsive: true, // Make the chart responsive
        maintainAspectRatio: false, // Disable aspect ratio to customize height
        plugins: {
          legend: {
            display: false,
          },
        },
        scales: {
          x: {
            grid: {
              color: '#084873',
            },
          },
          y: {
            beginAtZero: true,
            ticks: {
              stepSize: 1,
            },
            grid: {
              color: '#084873',
            },
          },
        },
      },
    });
  }


}
