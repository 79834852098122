import {
  AfterViewChecked,
  AfterViewInit,
  Component,
  Input,
  OnChanges,
  OnInit,
  SimpleChanges,
} from '@angular/core';
import { v4 as uuidv4 } from 'uuid';

import { Chart, registerables } from 'chart.js';
import { uniqueId } from 'lodash';
Chart.register(...registerables);

@Component({
  selector: 'app-doughnut-chart',
  templateUrl: './doughnut-chart.component.html',
  styleUrls: ['./doughnut-chart.component.scss'],
})
export class DoughnutChartComponent implements OnInit, AfterViewInit, AfterViewChecked {
  @Input() chartLable: any[];
  @Input() chartData: any[];
  ngAfterViewChecked(): void {
    this.chart.data.datasets[0].data = this.chartData;
    this.chart.update();
  }

  chartId: string;

  ngOnInit(): void {
    this.chartId = uuidv4();
  }
  // ngOnChanges(changes: SimpleChanges) {
  //   // this.chart.update();
  //   if (this.chart) {
  //     this.chart.data.datasets[0].update();
  //   }
  // }
  ngAfterViewInit(): void {
    this.createChart();
  }
  public chart: any;

  createChart() {
    const data = {
      labels: this.chartLable,
      datasets: [
        {
          label: 'العدد',
          data: this.chartData,
          backgroundColor: ['rgb(80,205,137,255)', 'rgb(242,65,108,255)', 'rgb(228, 230, 239)'],
          borderWidth: 0,
          hoverOffset: 5,
          borderRadius: 3,
          spacing: 3,
        },
      ],
    };

    this.chart = new Chart(this.chartId, {
      type: 'doughnut',
      data: data,
      options: {
        responsive: true, // Make the chart responsive
        maintainAspectRatio: false, // Disable aspect ratio to make it more flexible
        layout: {
          padding: 5,
        },
        cutout: '80%',
        plugins: {
          legend: {
            display: false,
          },
        },
      },
    });
  }

}
